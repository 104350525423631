import Tracking from './vendor/tracking.js';
import 'jssocials';

// add ezoic library to requirejs config
requirejs.config({
  paths: {
    'ezstandalone': '//www.ezojs.com/ezoic/sa.min'
  }
});

/* Load the ezoic library as quick as possible */
require(['ezstandalone']);

// define stuff 

// checks if user has en-GB locale
function isUserFromUK() {
  return _.includes(navigator.languages, 'en-GB');
}

function addAds(spage){
	$.get('/api/?action=pageoptions&path=/assets/ads/'+spage+'/', function(response){
		if(response['dashboard2.griddata'] && response['dashboard2.griddata'].length > 0){
			var oAdsCollection = response['dashboard2.griddata'];
			var adTemplate = "";
			_.each(oAdsCollection, function(oAd){
				switch(oAd.pagetype){
          case 'textbox':
            var oSetting = oAd.pcopt;
            adTemplate += oSetting['textbox.content'] ; 
            break;
          case 'image':
            var oSetting = oAd.pcopt;
            var oPickerData = JSON.parse(oSetting['image.files.pickerdata']);
            var sUrl = _.get(oPickerData, 'docs[0].odUrl');
            sUrl = encodeURI(sUrl);

            var lightBox = (oSetting['image.action'] == "lightbox") ? "photos" : "";
            
            // var imgLink = (_.isEmpty(that.odPcOpt['image.linkurl'])) ? "#" : that.odPcOpt['image.linkurl']; 
            var imgLink = oSetting['image.linkurl.pickername'] || '#';
            var eventLabel = oSetting.gadget.title;
            
            var sTarget = (oSetting['image.linkurl.options.newtab'] == "1") ? "_blank" : "_self";
            var linktext = (!_.isEmpty(oSetting['image.linktext'])) ? oSetting['image.linktext'].trim() : "";

            // var sMarkup = '' +
            // '<div class="eleImage eleHeight" data-type="image" style="background-image:url(' + sUrl + ')" data-gallery="'+lightBox+'" data-remote="'+sUrl+'">' +
            // '<h4>'+ linktext +'</h4>'+'</div>';
            var sMarkup = '' +
            '<div class="text-center" style="margin-bottom: 10px;"> <img class="img-responsive img-fullwidth" src="'+ sUrl +'" alt="ad-img" />' +
            '<h4>'+ linktext +'</h4>'+'</div>';
            // Wrap image in anchor tag
            if (oSetting['image.action'] == "link") sMarkup = "<a class='rhads' href='" + imgLink + "' target='" + sTarget + "' data-event-label='" + eventLabel + "' data-event-tracking = 'true' data-event-action= 'RH Panel Ad Click'>" + sMarkup + "</a>";
            adTemplate += sMarkup;
            //adTemplate += '<div class="text-center" style="border: 2px solid #acacac; padding: 10px 15px; margin-bottom: 10px;"> <img src="'+ sUrl +'" alt="ad-img" /> </div>';
            break;
            case 'form-simple':
              var oSetting = oAd.pcopt;
              var eventLabel = oSetting.gadget.title;
              var formTitle = '<div class="add-on-apps-form"><h2 style="margin-top: 0px;">'+eventLabel+'</h2></div>';
              $('#od-col-subnav > .panel').before(formTitle);
              odTemplate.loadPlugin(odApp.plugins["form-simple"]).then(
                function(modThis){
                  modThis.target = $('#od-col-subnav > .panel');
                  // modThis.odPcOpt = {"form-simple.form": "contact", "form-simple.options.wrapcontrols": "0", "form-simple.options.recaptcha": "1"};
                  // (ian 26/5/21, from live) use ad's tile settings
                  modThis.odPcOpt = oSetting;
                  modThis.odPcOpt['form-simple.options.wrapcontrols'] = '0';
                  modThis.doRender();
                }
              )
            break;
          } 
      })
	  if (spage == "banner1"){
        $('.cms-contentauto').before(adTemplate);
      }
      else {
        $('#od-col-subnav > .panel').append(adTemplate);
        $(document.body).addClass('sidenavAdverts');
      }

      if(spage == "tools"){
        $(document).on('click', 'a#expand', function () {
          $('div#details').slideToggle();
          $('span.linktext').toggle();
        });
      }
		}
	});
}

function addCommunityMessage(srefPage){
  $('.cms-contentauto').after('<p style="padding-bottom: 15px; padding-top: 15px;">'+ odApp.site.comment[srefPage] +'</p')
}

function addWebinar() {
  if (odApp.site.messages.webinar) {
    $('.od-header .container').append('<a class="btn btn-bold webinar btn-shadow btn-light-blue-outline float-left" href="' + odApp.site.messages.webinar.url + '"><i class="' + odTemplate.expandIconClass(odApp.site.messages.webinar.icon) + '"></i>&nbsp;<span>' + odApp.site.messages.webinar.text + '</span></a>');
  }
}

function styleSocialIcons() {
  $(document).ready(() => {
    // $('.od-social > span').addClass('fa-fw');

    // twitter
    $('.od-social-twitter > span').css('cssText', 'color: #1da1f2 !important');
    // g+
    $('.od-social-google > span').css('cssText', 'color: #db4437 !important');

    // youtube
    // $('.od-social-youtube > span').removeClass('fa-youtube').addClass('fa-youtube-play');

    $('.od-social-youtube > span').css('cssText', 'color: #FF0000 !important');
  });
}

function generateTags(){
  var stemplate = '<div id="tip-tags" class=""><div class="panel-rhs panel-tags"><div class="panel-body text-center"><div class="tags"><div id="tag_cloud-2" class="widget widget_tag_cloud"><div class="tagcloud">';
    $('#od-topnav > li:first-child > ul > li').each(function(){
        var shref = $(this).find('> a').attr('href');
        var stext = $(this).find('> a').text();
        var isSelected = $(this).hasClass('selected');
        stemplate += '<a href="'+shref+'" class="tag-link '+ ((isSelected)? 'selected' : '') +'">'+stext+'</a>';
    })
    stemplate += '</div></div></div></div></div></div>';
    return stemplate;
}

function styleSigninButton() {
  let $signinBtn = $('#od-btn-signin');
  $signinBtn.text('Join in');
  // $signinBtn.append('<i class="fa fa-arrow-right" style="margin-left: 8px;" aria-hidden="true"></i>');
  $signinBtn.attr('href', '/join-in/');
  $signinBtn.attr('data-event-tracking', true);
  $signinBtn.text(odApp.site.joinin.string.header);
  $signinBtn.addClass('btn-shadow btn-primary btn-join-in');
  $signinBtn.removeClass('hide od-navbar-button');
}

function addJoinInTooltip() {
  let $joinIn = $('.btn-join-in');
  $joinIn.tooltip({
    html: true,
    // give custom class in template so we don't affect all tooltips with our styling changes
    template: '<div class="tooltip tooltip-join-in"><div class="tooltip-arrow"></div><div class="tooltip-inner"></div></div>',
    title: odApp.site.joinin.string["btn-tooltips"],
    placement: 'bottom'
  });
}



// run stuff
// require([odApp.plugins.drive.plugin_js], function (modDrivePlugin) {
// });

// turn youtube links into embeds
function getYoutubeId(url) {
  if (!url) {
    return 'error';
  }

  var regExp = /^.*(youtu.be\/|v\/|u\/\w\/|embed\/|watch\?v=|\&v=)([^#\&\?]*).*/;
  var match = url.match(regExp);

  if (match && match[2].length == 11) {
    return match[2];
  } else {
    return 'error';
  }
}
window.getYoutubeId = getYoutubeId

function embedYoutubeLinks(elParent) {
  // youtube stuff
  if (odApp.page.contenttype == 'drive') {
    $('.CMSgoogledocembed a', elParent).each(function () {
      var that = this;
      var href = $(this).attr('href');

      var youtubeId = getYoutubeId(href);

      if (youtubeId != 'error') {
        var sEmbedUrl = "https://www.youtube-nocookie.com/embed/" + youtubeId;
        sEmbedUrl += '?showinfo=0';
        var sBody = '<iframe frameborder="0" class="od-dash-embed type-video embed-responsive-item" allowfullscreen src="' + sEmbedUrl + '"></iframe>';
        $(this).parent().append(sBody);
        $(that).remove();
      }
    })
  }
}

odApp.plugins.drive.onEmbed = function () {
  embedYoutubeLinks(this.target);
  $('.CMSgoogledocembed h2', this.target).prependTo(".CMSgoogledocembed", this.target);
}

$('#footer-custom').insertAfter('#od-container');
$('#footer-inner').insertAfter('#footer-custom');
var tagsTemplate = '<div class="col-lg-4 col-md-4 tags"> '+ 
    '<div id="tag_cloud-2" class="widget widget_tag_cloud">'+
    '<h4 class="widget-title">Tags</h4>'+
    '<div class="thin_line"></div>';
tagsTemplate += generateTags();
tagsTemplate += '</div> </div>';
$('#tip-tags', '#footer-custom .ff').remove();
$('#footer-custom .ff').append(tagsTemplate);
$('#tip-tags .selected', '#footer-custom .ff').removeClass('selected');
$('.btn-join-in','#footer-custom').text(odApp.site.joinin.string.footer);

$.get('/api/?action=pageoptions&path=/assets/footer/', function (response) {
	if (response['dashboard2.griddata'] && response['dashboard2.griddata'].length > 0) {
		var oFooter = response['dashboard2.griddata'][0].pcopt;
		var elFooter = oFooter["textbox.content"];
		$('.inner', 'footer').after(elFooter);
	};
});

styleSocialIcons();
styleSigninButton();
addWebinar();

// lazyLoadYoutubeEmbeds();

addJoinInTooltip();

// old code, unchanged
// Cookie Law Compliant
/*requirejs([odApp.urls.cdnpath + '/js/cookieconsent/v3.0.3/build/cookieconsent.min.js'], function () {
  loadCss(odApp.urls.cdnpath + '/js/cookieconsent/v3.0.3/build/cookieconsent.min.css');
  window.cookieconsent.initialise({
    "palette": {
      "popup": {
        "background": "#e6e6e6",
        "text": "#000000"
      },
      "button": {
        "background": "#7246b9"
      }
    },
    "showLink": false, */
    /* "elements": {
    "header": '<span class="cc-header">Cookie Notice</span>&nbsp;',
    "message": '<span id="cookieconsent:desc" class="cc-message">In order to provide you with the best experience this website is set to allow cookies. Cookies are small bits of information that we place on your computer. The cookies used help us to gather information about the pages you visit on this website allowing us to make your browsing experience better. To find out more about our use of cookies and your privacy please read our Privacy Policy. By closing this message and browsing the site, you consent to the use of cookies</span>',
    "messagelink": '<span id="cookieconsent:desc" class="cc-message">{{message}} <a aria-label="learn more about cookies" tabindex="0" class="cc-link" href="/privacy/" target="_blank">{{link}}</a></span>',
    "dismiss": '<a aria-label="dismiss cookie message" tabindex="0" class="cc-btn cc-dismiss">{{dismiss}}</a>',
    "allow": '<a aria-label="allow cookies" tabindex="0" class="cc-btn cc-allow">{{allow}}</a>',
    "deny": '<a aria-label="deny cookies" tabindex="0" class="cc-btn cc-deny">{{deny}}</a>',
    "link": '<a aria-label="learn more about cookies" tabindex="0" class="cc-link" href="/privacy/" target="_blank">{{link}}</a>',
    "close": '<span aria-label="dismiss cookie message" tabindex="0" class="cc-close">{{close}}</span>',
    }
    /*  */
    /*"content": {
      "header": '<span class="cc-header">Cookie Notice</span>&nbsp;',
      "dismiss": 'Got it!',
      "allow": "Allow cookies",
      "deny": "Decline",
      "link": "Learn more",
      "href": "/privacy/",
      "close": "&#x274c;",
      "message": '<span id="cookieconsent:desc" class="cc-message">In order to provide you with the best experience this website is set to allow cookies. <div class="extra-content" style="display:none;">Cookies are small bits of information that we place on your computer. The cookies used help us to gather information about the pages you visit on this website allowing us to make your browsing experience better. To find out more about our use of cookies and your privacy please read our <a href="/privacy/">Privacy Policy</a>.<br/> <b>By closing this message and browsing the site, you consent to the use of cookies</b></div><a href="javascript:void(0)" class="changeElem"><span class="showtext" >Show More</span><span class="showtext" style="display:none">Show Less</span></a></span>',
    }
  })
});

$(document).on('click', 'a.changeElem', function () {
  $('.extra-content').slideToggle();
  $('span.showtext').toggle();
}); */

//show page icons, initially Page Index for tools pages	
odApp.page.contentoptions["folder.options.showicons"] = 1;

//################### VIDEOS ############################
//improve loading of page when videos present
$('.iframe-video').find('iframe[pre-src]').each(function () {
  $(this).attr('src', $(this).attr('pre-src'));
});


//if not on a mobile view video in pop up. 
if (odTemplate.bs_width != 'xs') {
  var aVideos = $(".youtube");
  if (aVideos.length > 0) {
    requirejs(['template'], function () {
      // var sPath = '/assets/js/Bootstrap-Youtube-Popup-Player-Plugin-master/bootstrap.youtubepopup.js';
      var sPath = odApp.urls.cdnpath + '/js/bootstrap-youtube-popup-player/bootstrap.youtubepopup.rfr.js';
      requirejs([sPath], function () {
        aVideos.YouTubeModal({
          autoplay: 1,
          rel: 0,
          width: 853,
          height: 480
        });
      });
    });
  };
};


//--------------------------------------------------------------------
//what does this do?
//--------------------------------------------------------------------
// if (odApp.user.id == '0') {
//   if (navigator.userAgent.indexOf('Safari') != -1 && navigator.userAgent.indexOf('Chrome') == -1) {
//     $('#od-nav-container #od-navbar #od-navbar-header-group-3').css('padding-top', '0px');
//   } else if (navigator.userAgent.indexOf('Firefox') > -1) {
//     $('#od-nav-container #od-navbar #od-navbar-header-group-3').css('padding-top', '15px');
//   } else {
//     $('#od-nav-container #od-navbar #od-navbar-header-group-3').css('padding-top', '0px');
//   }
// }

//################### SOCIAL ICONS ############################
//remove square from class on social icons otherwise corners are rounded, should we do this in markup instead of here?
$('span.fa-twitter-square').addClass('fa-twitter').removeClass('fa-twitter-square');
$('span.fa-google-plus-square').addClass('fa-google-plus').removeClass('fa-google-plus-square');
$('span.fa-linkedin-square').addClass('fa-linkedin').removeClass('fa-linkedin-square');
$('span.fa-youtube-square').addClass('fa-youtube').removeClass('fa-youtube-square');
$('span.fa-envelope-square').addClass('fa-envelope').removeClass('fa-envelope-square');
$('span.fa-phone-square').addClass('fa-phone').removeClass('fa-phone-square');

//########################################### Add logged in user attr #####################################################
$(document.body).attr('od-loggedin', odApp.user.isloggedin);
//########################################### PAGE CLASSES ##################################################
var isTipCategory = location.pathname.match(/\/tips\/(\w+?)\/$/);
if (isTipCategory) {
  $('body').addClass('tip-category');
}
var isTipChild = location.pathname.match(/\/tips\/(.+?)\/.+?$/);
if (isTipChild) {
  $('body').addClass('tip-child');
}


var sPageName = (document.body.className.split(' page-')[1]).split(' ')[0];
var sRootPageName = (document.body.className.split('root-page-')[1]).split(' ')[0];
//differentiate between top level and child pages 'cos they'll both have class e.g. root-page-examples
if (sPageName != sRootPageName) sRootPageName += '-child';
// if (odApp.page.rfview) sRootPageName += '-child';

odApp.log('sRootPageName', sRootPageName);
// console.log('sRootPageName', sRootPageName, 'sPageName', sPageName);

switch (sRootPageName) { //(slugify(odApp.page.name)){ 

  case 'tools':
  case 'tools-child':
	  addAds('tools');

    if (odTemplate.bs_width == 'xs' || odTemplate.bs_width == 'sm') {

      var headingActivity = $('body.page-copy-folder #cms-content h2').first().clone('true', 'true');
      $('body.page-copy-folder #cms-content h2').first().remove();
      var elemActivity = $("body.page-copy-folder .cms-contentauto #myApp #copy-folder-container .col-md-6:first-child");
      $(elemActivity).prepend(headingActivity);


      var headingActivity2 = $('body.page-copy-folder #cms-content h2').last().clone('true', 'true');
      $('body.page-copy-folder #cms-content h2').last().remove();
      var elemActivity2 = $("body.page-copy-folder .cms-contentauto #myApp #copy-folder-container .col-md-6:last-child");
      $(elemActivity2).prepend(headingActivity2);
      $("body.page-copy-folder .cms-contentauto #myApp #copy-folder-container .col-md-6 h2.col-lg-6.col-md-6.text-left").removeClass('col-lg-6 col-md-6 text-left');

    }
      
    break;

  case 'news-child':
	  addAds('blog');
    break;
	  
  case 'gsuitetips-live':
  case 'home':
      addAds('home');
      break;

  case 'news':
	  addAds('blog');
    addCommunityMessage('news');
    // $(document).ajaxComplete(function (event, request, settings) {
      // $('.folder-blog .media-left img[pre-src^="/assets/images/thumbs"]').attr('src', '/assets/css/template/solveto/images/placeholder_logo.png');
    // });

    break;

  case 'tips-child':
  case 'tips-child-child':

    $('.od-comments .col-lg-8').removeClass('col-lg-8').addClass('col-lg-12');

    // appendAllAds()
    addAds('tips');
    addAds('banner1');
    addCommunityMessage('tips');

    var stemplate = generateTags();
    $('#tip-tags',  '#od-col-subnav').remove();
    $('#od-col-subnav .panel').prepend(stemplate);

    if(odApp.page.rfview != true){
      showMetaDescription();
    }
    break;

  case 'add-on-apps':
    showMetaDescription();
    /* Event tracking - Add on Apps */
    var func_on_Embed = odApp.plugins.drive.onEmbed;
    odApp.plugins.drive.onEmbed = function () {
      func_on_Embed();
      addonTracking();
    };
    addAds('add-on-apps-home');
    break;
	
  case 'join-in':
    odApp.plugins.dashboard2.onRender = function () {
      $('img.brand-logo').attr('src', odApp.template.logos.brandbar);
      $( "#mc_embed_signup_scroll" ).wrapAll( "<form action='//gsuitetips.us2.list-manage.com/subscribe/post?u=ef0215917168feb8cc4f4552f&amp;id=fe404cfc03' method='post' id='mc-embedded-subscribe-form' name='mc-embedded-subscribe-form' class='validate' target='_blank'/>");
    }
  //Removing as now only tiles required - 2184
	// $('#cms-content-extra').addClass('col-sm-offset-4 col-sm-4 panel panel-default').load('/assets/html/panels/join-in-form.html', function(){
	// 	$('#cms-content-extra img').attr('src', odApp.template.logos.brandbar);
	// });
    break;

     // -----------------------------------------------------
    // GAP-116 Add a form to right side of pages
    // -----------------------------------------------------
    case 'add-on-apps-child':
      addAds('add-on-apps');
      showMetaDescription();
      
     //stop dynamicNavigate because formsimple loader below won't fire
     odTemplate.loadPlugin(odApp.plugins.folder).then(function(m){
         m.dynamicNavigate = function(oFile){window.location = oFile.href}
     });
	  
     odApp.plugins.drive.onEmbed = function () {//style links as buttons
         $('body .CMSgoogledocembed p:last-child a').addClass('btn btn-shadow btn-default');
       };
	   
	    /* Event tracking - Add on Apps */
      var func_on_Embed = odApp.plugins.drive.onEmbed;
      odApp.plugins.drive.onEmbed = function () {
        func_on_Embed();
        addonTracking();
      };
      addAds('add-on-apps-home');
     break;
    }
     $('.CMSgoogledocembed p span[style*="width"]').parent().css('text-align', 'center');

//--------------------------------------------------------------------
//inject a menu item to make it easy to compare with old site
//--------------------------------------------------------------------
/* var elMenu = $('#od-nav-account-body > .dropdown-menu');
elMenu.append('<li class="divider"></li>');
//fix differences between old/new paths
var sUrl = window.location.pathname;
sUrl = sUrl.replace('/tips/', '/google-');
sUrl = sUrl.replace('-in-', '-');

sUrl = 'http://gappstips.com' + sUrl;
var sLink = '<li><a target=\"oldsite\" href=\"' + sUrl + '">Open on old site</a></li>';
elMenu.append(sLink); */

//finally set an attribute to signal template is reaady - useful for css
$('body').attr('data-odio-extras-init', 'true');

$(function () {
  var $loginProviders = $('#login-page-signin-providers')
  
  $loginProviders.attr('class', null)
  $loginProviders.addClass('col-md-6')

})

// $(document).ajaxComplete(function () {
    // $('#od-header .container').append($('#od-socialicons'));
  // });


function showSignupModal() {
  $(document).ready(() => {
    let shownObj = JSON.parse(localStorage.getItem('shownJoinInModal'));

    let shouldShow = false;
    if (!shownObj || (shownObj && moment().diff(moment(shownObj.timestamp)) >= 0)) {
      shouldShow = true;
    }

    // dont show for logged in users
    if (odApp.user.id != 0) {
      shouldShow = false;
    }

    if (shouldShow) {
      window.setTimeout(() => {
        $('#join-in-modal img').attr('src', odApp.template.logos.brandbar);
        let textTemplate = odApp.site.joinin.string["btn-tooltips"];

        $(textTemplate).insertAfter('#join-in-modal img');
        var href = $('#join-in-modal .btn-login-google').attr('href');
        $('#join-in-modal .btn-login-google').attr('href', href + '?from=' + window.location.pathname);
        $('#join-in-modal').modal('show');

        localStorage.setItem('shownJoinInModal', JSON.stringify({
          timestamp: moment().add(12, 'hours').format(),
          shown: true,
        }));
      }, 5000);
    }
  })
}

$(function () {
  if (screen.width <= 768) {
    $('#od-col-subnav').appendTo('#od-col-content')
  }
})

Tracking.events.onButtonClick({
  // debug: true,
  defaults: {
    action: 'Button Clicked',
  }
});

// Copy folder event tracking
$(document).ajaxComplete(() => {
  Tracking.events.onButtonClick({
    selector: '#btnpreview',
    defaults: {
      action: 'Preview',
      label: 'Preview'
    }
  });
  Tracking.events.onButtonClick({
    selector: '#btncancel',
    defaults: {
      action: 'Cancel',
      label: 'Cancel'
    }
  });
  Tracking.events.onButtonClick({
    selector: '#btnexecute',
    defaults: {
      action: 'Go',
      label: 'Execute'
    }
  });
   Tracking.events.onButtonClick({
    selector: '#btnrun',
    defaults: {
      action: 'Go',
      label: 'Execute'
    }
  });
	Tracking.events.onButtonClick({
	  selector: '.rhads',
	  defaults: {
        action: 'RH Panel Ad Click',
        label: 'RH Panel Ad Click Label'
      }
	});

})



showSignupModal();


Tracking.runAll({
  beforeUTM: () => {
    // Add utm-destinations to body (required for UTM tracking)
    $('body').attr('data-utm-destinations', 'overdrive.io,intranet-demo.overdrive.io,www.refractiv.co.uk');
  }
});

function updateBanner(oConfig){
  console.log(oConfig);
  /* Add Banner button and sub heading on home page */
  $('#od-pageheader-h1', '#pageid-1').after('<a href="/tools/" class="btn btn-shadow btn-secondary btn-lg animatedxx fadeInxx" data-animation="animatedxx fadeInxx">'+oConfig.btn1+'</a><a href="/tips/" class="btn btn-shadow btn-default btn-lg animatedxx fadeInxx" data-animation="animatedxx fadeInxx">'+oConfig.btn2+'</a>')
  $('#od-pageheader-h1', '#pageid-1').append('<span class="smartline">'+ oConfig.subheading +'</span>');
}
window.updateBanner = updateBanner;

function addonTracking(){
  var regExp = new RegExp("//" + location.host + "($|/)");
  $('a.btn', '#cms-contentauto').each(function(){
    var elAnchor = $(this);
    var slink = elAnchor.attr('href') || '';
    var isLocal = (slink.substring(0,4) === "http") ? regExp.test(slink) : true;
    if(window['ga'] && !isLocal){
      elAnchor.off('click').on('click', function(){
        var slabel = $('#od-pageheader-h1').text();
        ga('send', 'event', {
          transport: 'beacon',
          eventCategory: "Add on Apps Affiliate",
          eventAction: "Button click",
          eventLabel: slabel
        });
      })
    }
  });
}

function showMetaDescription(){
  $('.page-subtitle').remove();
  var metaDescription = $("meta[name='description']").attr('content');
  $('.cms-contentauto').prepend('<h2 class="page-subtitle">' + metaDescription + '</h2>');
}

// Ezoic Ads - e.g. ezoic-pub-ad-placeholder-103
/* Original ad positions but could be changed by us at anytime
103 - Top banner
119 - In footer.html - comes in as part of index.html
126 - Sidebar tile top
127 - Sidebar tile second down
128 - Sidebar tile third down
129 - Sidebar tile fourth down
130 - ?
131 - ?
133 - In content 1 - main tile
*/

/* #1916 Add In Content */

/* Ezoic */
/* Use global mutation observer and instead of looking in the mutation content we
serach the entire doc for new instances of ad placeholders */

class Ezoic {
  constructor() {
    this.init();
  }
  log() {
    if(!this.debug) return;
    console.log('Ezoic', ...arguments);
  }
  init() {
    this.contentId = "od-doc-document-first";
    this.adsDisplayed = [];
    this.automaticAds = [100, 101, 102]; // skip these ads
    this.debug = false;
  }
  createPlaceholderId(adId) {
    return 'ezoic-pub-ad-placeholder-' + adId;
  }
  createAdElement(adId) {
    var ad = document.createElement('div');
    ad.id = this.createPlaceholderId(adId);
    ad.className = 'ezoic-ad in-content-ads';
    if (this.debug) {
      const newContent = document.createTextNode(ad.id);
      ad.appendChild(newContent);
    }
    return ad;
  }
  contentExists() {
    return document.getElementById(this.contentId);
  }
  contentAdExists(adNum) {
    return document.getElementById(this.createPlaceholderId(adNum));
  }
  contentInsert() {
    var content = document.getElementById(this.contentId);
    var h3 = content.querySelector('h3');
    var h3 = content.getElementsByTagName('h3');
    var adNum = 133;
    for (var i = 0; i < h3.length && i <3; i++) {
      if (!this.contentAdExists(adNum)) {
        var ad = this.createAdElement(adNum);
        $(ad).insertBefore(h3[i]);
      }
      adNum++;
    }
  }
  isAutomaticAd(adId) {
    return this.automaticAds.includes(adId);
  }
  findNewAds() {
    var currentPath = window.location.pathname;
    var newAds = [];
    var ads = document.querySelectorAll('[id^=ezoic-pub]:not([hidden])');
    ads.forEach(ad => {
      var id = ad.id;
      if (id) {
        var adId = id.split('-').pop();
        if (adId) {
          adId = parseInt(adId);
          if (!this.isAutomaticAd(adId)) {
            if (!this.adsDisplayed.includes(adId)) {
              newAds.push(adId);
            } else {
              //console.warn('ad already displayed', adId);
            }
          }
        }
      }
    });
    if (newAds.length) {
      if (this.adsDisplayed.length == 0) {
        this.log('define',newAds);
        ezstandalone.cmd.push(function () {
          ezstandalone.define.apply(null, newAds);
          ezstandalone.enable();
          ezstandalone.display();
        });
      } else {
        this.log('displayMore',newAds);
        ezstandalone.cmd.push(function () {
          ezstandalone.displayMore.apply(null, newAds);
        });
      }
      this.path = currentPath;
      this.adsDisplayed = this.adsDisplayed.concat(newAds);
    }
  }
  refreshNewAds() {
    ezstandalone.cmd.push(function () {
      ezstandalone.refresh();
    });
  }
  defineAds() { // Experimental
    ezstandalone.cmd.push(function () {
      ezstandalone.define(133, 134, 135, 119, 126, 127, 128, 129, 103);
      ezstandalone.enable();
      ezstandalone.display();
    });
  }
  destroyAll() {
    for (var i = 0; i< this.adsDisplayed.length; i++) {
      var adId = this.adsDisplayed[i];
      ezstandalone.cmd.push(function () {
        try{
          ezstandalone.destroy(adId)
        } catch(e) {}
      });
    }
  }
  addInContent() {
    if (this.contentExists()) {
      this.contentInsert();
    }
  }
  applyAds() {
    this.addInContent();
    this.findNewAds();
  }
  observerCreate() {
    this.observer = new MutationObserver(mutations => {
      this.applyAds();
    });
  }
  observe() {
    this.observer.observe(document, { attributes: false, childList: true, characterData: false, subtree: true });
  }
  watchForChanges() {
    this.observerCreate();
    this.observe();
    this.applyAds(); // initial ads
  }
  run() {
    if (this.running) {
      console.warn('already running');
      this.applyAds();
      return;
    }
    console.warn('run');
    this.running = true;
    this.watchForChanges();
  }
}

require(['ezstandalone'], function () {
  window.ezoic = new Ezoic();
  window.ezoic.debug = location.search.includes('ez-debug');
  if (!location.search.includes('ez-disable')) {
    window.ezoic.run();
  }
});

function isFunction(functionToCheck) {
  return functionToCheck && {}.toString.call(functionToCheck) === '[object Function]';
}

class Tracking {
  constructor() {
    this.events = {
      onButtonClick: opts => {
        // No analytics - stop
        if (!window['ga']) {
          //console.warn('Tracking: analytics not available on this site, tracking will not work');
          return;
        }

        $(document).ready(() => {
          if (opts == undefined) {
            opts = {};
          }

          var debug = opts.debug;
          if (!opts.selector) {
            opts.selector = '[data-event-tracking="true"]';
          }

          var $elements = $(opts.selector);

          var clickBinder = $elements.on;
          if (opts.once == undefined) {
            opts.once = true; // default to true
          }
          if (opts.once) {
            clickBinder = $elements.one;
          }

          // unbind previous events defined by us
          $elements.off('click.eventtracking');

          clickBinder.call($elements, 'click.eventtracking', event => {
            var $el = $(event.currentTarget);

            if (opts.defaults == undefined) {
              opts.defaults = {};
            }

            var defaults$$1 = opts.defaults;
            if (defaults$$1.category == undefined) {
              defaults$$1.category = 'odPageTitle'; // default
            }

            // Get data attributes
            var data = {
              category: $el.data('event-category'),
              action: $el.data('event-action'),
              label: $el.data('event-label'),
            };

            var specialAttrs = {
              category: {
                'odPageTitle': function odPageTitle() {
                  if (odApp && odApp.page && odApp.page.title) {
                    return odApp.page.title;
                  }

                  return document.title;
                },
              },
              action: {},
              label: {
                'href': function href() {
                  return $el.attr('href');
                },
                'content': function content() {
                  return $el.text().trim();
                }
              }
            }

            var eventOpts = {
              category: '',
              action: '',
              label: ''
            };

            for (var key in data) {
              var val = data[key] || defaults$$1 && defaults$$1[key];

              if (val) {
                eventOpts[key] = val;
              } else {
                val = defaults$$1[key];
              }

              var specialFunc = specialAttrs[key][val];
              if (specialFunc) {
                eventOpts[key] = specialFunc();
              }
            }

            if (debug) {
              event.preventDefault();
              console.group();
              console.log('category:', eventOpts.category);
              console.log('action:', eventOpts.action);
              console.log('label:', eventOpts.label);
              console.groupEnd();
            } else {
              ga('send', 'event', {
                transport: 'beacon',
                eventCategory: eventOpts.category,
                eventAction: eventOpts.action,
                eventLabel: eventOpts.label,
              });
            }
          })
        })
      }
    }
  }

  runAll(opts) {
    if (opts == undefined) {
      opts = {};
    }

    if (isFunction(opts.beforeUTM)) {
      opts.beforeUTM();
    }
    this.utmTracking();
  }

  utmTracking() {
    var $ = window.$ || window.jQuery;

    var isODSite = true;
    var odApp = odApp || null;

    // replace slugify if odApp doesn't exist
    var slugify = null;
    if (odApp) {
      slugify = odApp.tools.strings.slugify;
    } else {
      isODSite = false;

      slugify = function (text) {
        return text.toString().toLowerCase().trim()
          .replace(/[\u0300-\u036f]/g, '') // remove all separated accents
          .replace(/\s+/g, '-')            // replace spaces with -
          .replace(/&/g, '-and-')          // replace & with 'and'
          .replace(/[^\w\-]+/g, '')        // remove all non-word chars
          .replace(/\-\-+/g, '-')          // replace multiple '-' with single '-'
      };
    }

    // returns URL params in an object
    var getParams = function (url) {
      var params = {};
      var parser = document.createElement('a');
      parser.href = url;
      var query = parser.search.substring(1);
      var vars = query.split('&');
      for (var i = 0; i < vars.length; i++) {
        var pair = vars[i].split('=');
        params[pair[0]] = decodeURIComponent(pair[1]);
      }
      if (params[''] === 'undefined') {
        return {};
      }
      return params;
    };

    var bodyDest = $('body').attr('data-utm-destinations');
    if (!bodyDest) {
      console.warn('No data-utm-destinations specified on the body element, UTM tracking will not be added.');
      return;
    }
    var destinations = bodyDest.split(',');

    $(document).ready(function () {
      var elParent = $('.od-container');
      $(document).ajaxComplete(function () {
        $('a:not(.utm-tracked)', elParent).each(function (_ign, el) {
          if (destinations.indexOf(el.host) == -1 || $(el).parents('.col-subnav').length >= 1) {
            return;
          }

          //add class utm-tracked it doesn't get processed again
          $(this).addClass('utm-tracked');

          var href = el.href;

          var params = getParams(href);

          var path = String(location.pathname);
          var cleanPath = null;
          if (path[0] && path[0] === '/') {
            path = path.slice(1);
          }
          // if it's the home page, set campaign to 'home-page'
          if (path.length == 0) {
            cleanPath = 'home-page';
          } else {
            var last = path[path.length - 1];
            if (last && last === '/') {
              path = path.slice(0, -1);
            }

            cleanPath = path.replace(/\//g, '_');
          }

          var closestMedium = $(el).closest('[data-utm-medium]').attr('data-utm-medium');
          if (!closestMedium) {
            closestMedium = 'page-body';
          }

          var campaign = cleanPath;
          if (isODSite) {
            campaign += '-' + odApp.page.id;
          }

          var dataContent = $(el).attr('data-utm-content');
          var content = dataContent || $(el).text().trim().replace(/\s\s+/g, ' ').replace(/^(.{32}[^\s]*).*/, "$1");
          closestMedium = window.location.pathname != "/add-on-apps/google-website-builders/overdrive/" ? closestMedium : 'addon-apps';
          
          // GAP-133
          if($(el).hasClass('webinar')){ closestMedium = 'webinar-flash'; }
          switch(campaign + '|' + slugify(content)){
            case 'home-page|find-out-more':
              closestMedium = 'homepage-banner';
              break;
            case 'events|register':
              closestMedium = 'event-page';
              break;
            case 'contact|overdrive':
            case 'contact|overdriveio':
            case 'contact|demo-site':
            case 'contact|get-in-touch':
            case 'contact|more':
              closestMedium = 'contact-page';
              break;
          }

          params['utm_source'] = window.location.host;
          params['utm_medium'] = closestMedium;
          params['utm_campaign'] = campaign;
          params['utm_content'] = slugify(content);

          var serialized = $.param(params);
          el.search = serialized;
        });
      });
    });
  }
}

let tracking = new Tracking();

export default tracking;